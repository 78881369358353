import { Controller } from '@hotwired/stimulus';

export default class ScrollSliderController extends Controller {
  static targets = ['items', 'previousButton', 'nextButton'];

  static values = {
    autoScroll: Boolean,
    interval: Number,
  };

  connect() {
    this.itemWidth = this.itemsTarget.scrollWidth / this.itemsTarget.children.length;
    this.checkButtonVisibility();
    window.addEventListener(
      'resize',
      () => {
        this.checkButtonVisibility();
        this.resetScrollPosition();
      },
      { passive: true },
    );

    // Bind methods to maintain the correct context
    this.stopAutoScroll = this.stopAutoScroll.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    // Add event listeners for user interaction
    this.itemsTarget.addEventListener('mousedown', this.handleMouseDown, { passive: false });
    this.itemsTarget.addEventListener('wheel', this.stopAutoScroll, { passive: true });
    this.itemsTarget.addEventListener('touchstart', this.handleTouchStart, { passive: true });
    this.itemsTarget.addEventListener('touchmove', this.handleTouchMove, { passive: false });

    if (this.autoScrollValue) {
      this.startAutoScroll();
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.checkButtonVisibility);
    this.itemsTarget.removeEventListener('mousedown', this.handleMouseDown);
    this.itemsTarget.removeEventListener('wheel', this.stopAutoScroll);
    this.itemsTarget.removeEventListener('touchstart', this.handleTouchStart);
    this.itemsTarget.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    this.stopAutoScroll();
  }

  resetScrollPosition() {
    this.itemsTarget.scrollLeft = 0;
  }

  // Desktop event handlers
  handleMouseDown() {
    this.isDragging = true;
    this.stopAutoScroll();
    document.addEventListener('mousemove', this.handleMouseMove, { passive: false });
    document.addEventListener('mouseup', this.handleMouseUp, { passive: true });
  }

  handleMouseMove() {
    // Logic for handling mouse movement while dragging, if necessary
  }

  handleMouseUp() {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  // Mobile event handlers
  handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
    this.touchStartY = event.touches[0].clientY;
    this.isDragging = false;
  }

  handleTouchMove(event) {
    const moveX = event.touches[0].clientX - this.touchStartX;
    const moveY = event.touches[0].clientY - this.touchStartY;
    if (Math.abs(moveX) > Math.abs(moveY)) {
      this.isDragging = true;
    }
  }

  // Shared stop auto-scroll logic
  stopAutoScroll() {
    if (this.isDragging) {
      clearInterval(this.autoScrollInterval);
      this.autoScrollInterval = null;
    }
  }

  next() {
    const singleItemWidth = Math.floor(this.itemsTarget.children[0].getBoundingClientRect().width);
    const maxScrollPosition = this.itemsTarget.scrollWidth - this.itemsTarget.offsetWidth;
    const remainingScroll = maxScrollPosition - this.itemsTarget.scrollLeft;

    if (remainingScroll >= singleItemWidth) {
      // If there's room to scroll by one more item's width, do it
      this.itemsTarget.scrollLeft += singleItemWidth;
    } else {
      // If we're at the end, loop back to the start
      this.itemsTarget.scrollLeft = 0;
    }

    this.checkButtonVisibility();
  }

  previous() {
    const singleItemWidth = Math.floor(this.itemsTarget.children[0].getBoundingClientRect().width);

    if (this.itemsTarget.scrollLeft > singleItemWidth) {
      // If there's room to scroll back by one more item's width, do it
      this.itemsTarget.scrollLeft -= singleItemWidth;
    } else if (this.itemsTarget.scrollLeft > 0) {
      this.itemsTarget.scrollLeft = 0;
    } else {
      // If we're at or near the start, go to the end
      this.itemsTarget.scrollLeft = this.itemsTarget.scrollWidth - this.itemsTarget.offsetWidth;
    }

    this.checkButtonVisibility();
  }

  checkButtonVisibility() {
    // If there are no button targets, exit the function
    if (!this.hasPreviousButtonTarget || !this.hasNextButtonTarget) {
      return;
    }

    const isOverflowing = this.itemsTarget.scrollWidth > this.itemsTarget.offsetWidth;
    this.previousButtonTarget.style.display = isOverflowing ? 'block' : 'none';
    this.nextButtonTarget.style.display = isOverflowing ? 'block' : 'none';
    if (isOverflowing) {
      this.itemsTarget.classList.add('slider-enabled');
    } else {
      this.itemsTarget.classList.remove('slider-enabled');
    }
  }

  startAutoScroll() {
    clearInterval(this.autoScrollInterval);
    this.autoScrollInterval = setInterval(() => {
      this.next();
    }, this.intervalValue || 3000); // Default to 3000ms if no interval is set
  }

  autoScrollValueChanged() {
    if (this.autoScrollValue) {
      this.startAutoScroll();
    } else {
      this.stopAutoScroll();
    }
  }
}
