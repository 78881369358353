import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

import { formToQueryString } from '../../utils';

// there are similarities here with the auto-submit controller.
// we may choose to remove the redundancy between them at some point.

export default class extends Controller {
  addLoader() {
    Array.from(this.element.children).forEach((child) => child.classList.add('is-loading'));

    const loader = document.createElement('div');
    loader.id = 'loader';

    this.element.appendChild(loader);
  }

  removeLoader() {
    Array.from(this.element.children).forEach((child) => child.classList.remove('is-loading'));
    const loader = document.getElementById('loader');
    if (loader) {
      loader.remove();
    }
  }

  change(event) {
    this.addLoader();

    const isLoadMore = event.params.loadMore;

    const modelPath = this.element.getAttribute('data-model-filter-path');
    const isModelPage = window.location.pathname === modelPath;

    const offset = isLoadMore ? document.querySelector('.g-derivatives__rows').children.length ?? 0 : 0;

    const paginationParams = { limit: 20, offset };
    const filtersParams = {};

    // handle submit buttons that have names and values
    if (event.target.nodeName === 'BUTTON' && event.target.name && typeof event.target.value !== 'undefined') {
      filtersParams[event.target.name] = event.target.value;
    }

    if (isModelPage && !isLoadMore) {
      const pageUrl = `${modelPath}?${formToQueryString(this.element, filtersParams)}`;
      window.history.pushState({ turbolinks: {} }, '', pageUrl);
    }

    const url = `${modelPath}/deal_rows?${formToQueryString(this.element, { ...filtersParams, ...paginationParams })}`;

    Rails.ajax({
      url,
      type: this.element.getAttribute('method') || 'get',
      dataType: 'json',
      success: (result) => {
        const existingData = document.querySelector('.g-derivatives__rows');

        if (isLoadMore) {
          existingData.innerHTML += result.html;
        } else {
          existingData.innerHTML = result.html;

          const totalEntrieselement = document.querySelector('[data-model-filter-total-entries]');
          totalEntrieselement.innerHTML = `${result.total_entries} vehicles found`;
        }

        const showLoadMoreButtonClass = 'g-derivatives__more--show';
        const loadMoreButton = document.querySelector('.g-derivatives__more');

        if (result.total_entries > existingData.children.length) {
          loadMoreButton.classList.add(showLoadMoreButtonClass);
        } else {
          loadMoreButton.classList.remove(showLoadMoreButtonClass);
        }

        this.removeLoader();
      },
    });
  }
}
