import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    personalQuoteTypes: Array,
    financeLeaseTypes: Array,
  };

  static targets = [
    'quoteType',
    'arrangement',
    'message',
    'maintenance',
    'option',
    'options',
    'maintenanceType',
    'monthlyRental',
    'customInitialPayment',
    'messagePreset',
  ];

  updateArrangementFee(target) {
    const arrangementFee = this.isPersonalQuoteType(target)
      ? this.arrangementTarget.dataset.personal
      : this.arrangementTarget.dataset.business;

    this.arrangementTarget.value = parseFloat(arrangementFee).toFixed(2);
  }

  connect() {
    if (this.maintenanceTypeTarget.value === 'Non-maintained') {
      this.maintenanceTarget.value = '';
      this.maintenanceTarget.readOnly = true;
    }

    this.updateArrangementFee(this.quoteTypeTarget.value);
  }

  changeQuoteType(event) {
    this.updateArrangementFee(event.currentTarget.value);

    if (this.isFinanceLease(event.currentTarget.value)) {
      this.showBallotPayment();
    } else {
      this.hideBallotPayment();
    }

    if (this.isPersonalQuoteType(event.currentTarget.value)) {
      this.updateMessageFields('/business', '/personal');
    } else {
      this.updateMessageFields('/personal', '/business');
    }
  }

  updateMessageFields(prevType, newType) {
    this.updateInputMessage(prevType, newType);
    this.updatePresetMessages(prevType, newType);
  }

  updateInputMessage(prevType, newType) {
    const updatedMessage = this.messageTarget.value.replace(prevType, newType);

    this.messageTarget.value = updatedMessage;
  }

  updatePresetMessages(prevType, newType) {
    this.messagePresetTarget.children.forEach((element) => {
      const updatedValue = element.innerHTML.replace(prevType, newType);

      element.innerHTML = updatedValue;
    });
  }

  showBallotPayment() {
    document.getElementById('effective_rental-container').classList.add('d-none');
    document.getElementById('balloon_payment-container').classList.remove('d-none');
  }

  hideBallotPayment() {
    document.getElementById('balloon_payment-container').classList.add('d-none');
    document.getElementById('effective_rental-container').classList.remove('d-none');
  }

  changeMessageType(event) {
    const messageType = event.currentTarget.value;

    if (messageType === 'Free') {
      this.messageTarget.value = '';
    } else {
      const messageIndex = messageType.match(/\d+/g)[0];
      const messageId = `message_preset_${messageIndex}`;
      const messagePreset = document.getElementById(messageId);

      this.messageTarget.value = messagePreset.innerHTML
        .trim()
        .split('\n')
        .map((line) => line.trim().replace(/\s+/g, ' '))
        .join('\n\n');
    }

    this.messageTarget.focus();
  }

  changeMaintenanceType(event) {
    const maintenanceType = event.currentTarget.value;
    const maintenance_Price = this.maintenanceTarget.dataset.maintenanceprice;
    const maintainedRental = this.monthlyRentalTarget.dataset.maintainedrental;
    const monthlyRental = this.monthlyRentalTarget.dataset.monthlyrental;

    if (maintenanceType === 'Maintained') {
      this.monthlyRentalTarget.value = maintainedRental;
      this.maintenanceTarget.value = maintenance_Price;
    } else {
      this.monthlyRentalTarget.value = monthlyRental;
      this.maintenanceTarget.value = '';
    }

    if (maintenanceType === 'Non-maintained') {
      this.maintenanceTarget.readOnly = true;
    } else {
      this.maintenanceTarget.readOnly = false;
    }
  }

  removeOption(event) {
    event.preventDefault();
    const option = event.currentTarget;

    option.parentElement.remove();
  }

  addOption(event) {
    event.preventDefault();
    const options = this.optionsTarget;
    const option = this.optionTarget;
    const newOption = option.cloneNode(true);

    newOption.querySelector('input').value = '';
    options.appendChild(newOption);
  }

  initialPayment(event) {
    // the custom initial payments field should be enabled if the initial payments selected is zero
    const initialPayments = event.target.value;
    this.customInitialPaymentTarget.disabled = initialPayments !== '0';
  }

  isPersonalQuoteType(quoteType) {
    return this.personalQuoteTypesValue.includes(quoteType);
  }

  isFinanceLease(quoteType) {
    return this.financeLeaseTypesValue.includes(quoteType);
  }
}
