import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['navigationItem'];

  connect() {
    this.showColumn(1);
  }

  reload(event) {
    axios
      .get('car-comparison-tool.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        this.element.innerHTML = response.data.html;
        this.showColumn(event.target.dataset.position);
      });
  }

  navigate(event) {
    event.preventDefault();
    this.showColumn(event.currentTarget.dataset.position);
  }

  showColumn(position) {
    if ($(window).width() < 1200) {
      const activeLink = document.querySelector(`.c-comparison-navigation__link[data-position="${position}"]`);
      this.navigationItemTargets.forEach((target) => {
        target.classList.remove('is-active');
      });

      if (activeLink) {
        activeLink.closest('.c-comparison-navigation__item').classList.add('is-active');
      }

      $('.g-comparison__section-data').children().hide();
      $('.g-comparison__section-data')
        .children(`td:nth-child(${Number(position) + 1})`)
        .fadeIn();
    }
  }
}
