import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['makes', 'models', 'derivatives', 'term', 'mileage', 'initialPayment'];

  findVehicle(event) {
    event.preventDefault();

    this.render(0, this.vehicleParams());
  }

  addVehicle(event) {
    event.preventDefault();
    const derivative_id = event.target.dataset.derivativeId || event.target.value;
    const offerParams = {
      term: event.target.dataset.term,
      mileage: event.target.dataset.mileage,
      initial_payment: event.target.dataset.initialPayment,
    };

    this.addToCookie(derivative_id, offerParams);
  }

  updateVehicle(event) {
    event.preventDefault();
    const derivative_id = this.element.dataset.derivativeId;

    this.updateCookie(derivative_id, this.offerParams(event));
    this.render(derivative_id, {});
  }

  removeVehicle(event) {
    event.preventDefault();
    const derivative_id = this.element.dataset.derivativeId;

    event.target.parentElement.parentElement.style.transition = 'opacity .5s';
    event.target.parentElement.parentElement.style.opacity = 0;

    this.deleteFromCookie(derivative_id);
  }

  vehicleParams() {
    const params = {
      make_id: this.makesTarget.value,
      model_id: this.modelsTarget.value,
      derivative_id: this.derivativesTarget.value,
    };
    return params;
  }

  offerParams(event) {
    const params = {
      term: event.target.nodeName === 'BUTTON' ? event.target.innerHTML : this.termTarget.innerHTML,
      mileage: this.mileageTarget.value,
      initial_payment: this.initialPaymentTarget.value,
    };
    return params;
  }

  render(derivative_id, params) {
    axios
      .get(`car-comparison-tool/${derivative_id}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params,
      })
      .then((response) => {
        this.element.innerHTML = response.data.html;
      });
  }

  deleteFromCookie(derivative_id) {
    const cookie = Cookies.get(this.channelCookie());

    if (cookie) {
      let cookieData = JSON.parse(cookie);
      cookieData = cookieData.filter((hash) => {
        return !(derivative_id in hash);
      });
      this.setCookie(cookieData);
    }
  }

  addToCookie(derivative_id, params) {
    const cookie = Cookies.get(this.channelCookie());
    const newDerivative = {};
    const cookieData = cookie ? JSON.parse(cookie) : [];

    newDerivative[derivative_id] = params;
    cookieData.push(newDerivative);
    this.setCookie(cookieData);
  }

  updateCookie(derivative_id, params) {
    const cookie = Cookies.get(this.channelCookie());

    if (cookie) {
      const cookieData = JSON.parse(cookie);
      cookieData.forEach((x, i) => {
        if (derivative_id in x) {
          cookieData[i][derivative_id] = params;
        }
      });
      this.setCookie(cookieData);
    }
  }

  setCookie(cookieData) {
    const daysUntilExpiry = 14;

    Cookies.set(this.channelCookie(), JSON.stringify(cookieData), { expires: daysUntilExpiry });
  }

  channelCookie() {
    const isVansChannel = window.location.pathname.startsWith('/van-leasing/');

    return isVansChannel === true ? 'comparable-vans' : 'comparable-cars';
  }
}
